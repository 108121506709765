import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import LogoGroup from "../../images/logogroup.png";

export default () => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" }, sourceInstanceName: { eq: "partner" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 150, quality: 100) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  const edges = allFile.edges.sort((a, b) => {
    const aName = a.node.childImageSharp.fluid.originalName[0];
    const bName = b.node.childImageSharp.fluid.originalName[0];
    return aName.localeCompare(bName);
  });
  return (
    <Container>
      <Table>
        <img width={960} src={LogoGroup} alt="logogroup" />
      </Table>
    </Container>
  );
};
const Table = styled.div`
  width: 1130px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: center;
  //img {
  //  margin-left: 70px;
  //  margin-top: 30px;
  //  height: 72px;
  //  &:first-child {
  //    margin-left: 0;
  //  }
  //  &:nth-child(6),
  //  &:nth-child(11),
  //  &:nth-child(16) {
  //    margin-left: 0;
  //  }
  }
`;

const Container = styled.div`
  width: 1130px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;
`;
